<template>
  <div class="bg-purple pb-[5vw] md:pb-[6.25vw]">
    <div class="absolute w-full h-full overflow-hidden md:overflow-visible">
      <SvgShapeGearSharp class="absolute top-[10vw] md:top-[13vw] -right-[15.5vw] md:right-[10vw] w-[30vw] md:w-[13vw] text-yellow" />

      <SvgShapeHalfCircle class="absolute top-[100vw] md:top-[16vw] w-[28vw] md:w-[12vw] text-turquoise left-[-12vw] md:left-[3vw]"
                          :rotate="30"
      />

      <SvgShapeCrescent class="absolute hidden md:block top-[18vw] md:top-[38vw] w-[20.7vw] md:w-[8vw] text-sky  right-[7vw] md:right-[10vw]"
                        :rotate="-40"
      />

      <SvgShapeEclipse :modifier="15"
                       class="absolute left-[28vw] md:left-[32.5vw] top-[37vw] md:top-[12vw] w-[44vw] md:w-[35vw] md:h-[43vw] m-auto text-pink will-change-auto"
      />
    </div>

    <div class="relative md:max-w-[42vw] mx-auto text-center">
      <h2 class="font-work font-extrabold leading-none text-[14vw] md:text-[7vw] m-auto md:mx-[2.75vw] mb-[44vw] md:mb-[20vw] text-white pt-[58vw] md:pt-[20vw] tracking-[-0.02em]">
        {{ title }}
      </h2>
    </div>

    <Container class="relative"
               ref="container"
    >
      <div v-for="(card, index) in cards.filter(card => !(!(complianceResultState.O7Compliance_AgeLimitPassed !== false) && card.gated))"
           :key="index"
           class="sticky top-[6vw]"
      >
        <CardBigVideo :index="index"
                      v-bind="card"
        />
      </div>
    </Container>
  </div>
</template>
<script lang="ts" setup>
import { animate, inView } from 'motion';
import { onMounted, ref, onUnmounted } from 'vue';
import type { CtaType } from '../../../types';
import { complianceResultState } from '@outfit7/o7compliance-client';

type SectionStoryVideoScrollerProps = {
  title: string
  cards: {
    index: string;
    title: string,
    background?: string,
    videoPlaylistId?: string,
    badge: string,
    gated: boolean,
    cta: CtaType,
  }[]
}

defineProps<SectionStoryVideoScrollerProps>();

const container = ref(null);
const inViewStoppers: Array<() => void> = [];

onMounted(() => {
  
  // Loop through container ref children and add inview animation that fades
  // out the previous card, and the opposite on exit

  const length = container.value.$el.children.length;

  for (let i = 1; i < length; i++) {
    const stopInViewDetection = inView(container.value.$el.children[i], () => {
      animate(container.value.$el.children[i - 1], { opacity: 0, scale: 0.5 }, { duration: 1 });

      return () => {
        animate(container.value.$el.children[i - 1], { opacity: 1, scale: 1 }, { duration: 1 });
      };

    }, { amount: 0.75 });
    inViewStoppers.push(stopInViewDetection);
  }
});

onUnmounted(() => {
  inViewStoppers.forEach(stopInViewDetection => stopInViewDetection());
});
</script>
