<template>
  <div class="rounded-[5vw] md:rounded-[1.25vw] my-8 overflow-hidden relative md:h-[50vw] max-h-[calc(100vh-24vw)] md:max-h-[calc(100vh-10vw)] h-[150vw] shadow-m "
       :style="{ clipPath: 'content-box' }"
  >
    <div class="md:py-[4.5vw] py-[10vw] px-[6vw] flex flex-col items-center justify-center h-full">
      <NuxtImg v-if="backgroundImage && !videoPlaylistId "
               format="auto"
               :src="background"
               alt=""
               class="absolute inset-0 object-cover w-full h-full"
               sizes="xs:85vw sm:85vw md:88vw lg:88vw xl:88vw xxl:88vw"
               loading="lazy"
      />

      <video v-else-if="!backgroundImage && !videoPlaylistId"
             autoplay
             muted
             playsinline
             disableremoteplayback="true"
             loop
             class="absolute inset-0 object-cover w-full h-full"
      >
        <source :src="background"
                type="video/mp4"
        >
      </video>

      <div v-else
           data-video
           class="absolute inset-0 w-full h-full"
      >
        <div :id="`video-player-${index}`" />
      </div>

      <div class="absolute inset-0 w-full h-full pointer-events-none"
           style="background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))"
      />

      <div>
        <div v-if="badge"
             class="z-10 bg-red block rounded-[1.25vw] md:rounded-[0.3vw] px-[2vw] md:px-[0.75vw] py-[1vw] md:py-[0.375vw] uppercase text-white font-oswald text-[5vw] md:text-[1.5vw] leading-[6vw] md:leading-[1.75vw] font-medium text-center tracking-[-0.01em] mb-[8vw] md:mb-[5vw]"
        >
          {{ badge }}
        </div>
      </div>

      <h2 class="pointer-events-none relative uppercase text-white text-[16vw] md:text-[10vw] leading-none md:tracking-[-0.02em] font-extrabold text-center font-work max-w-[64vw] mb-[8vw] md:mb-[5vw]">
        {{ title }}
      </h2>

      <div class="relative flex justify-center w-full">
        <ButtonRegular v-bind="cta"
                       :gate-link="gated"
                       @click="handleClick"
        >
          {{ cta.label }}
        </ButtonRegular>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
import { useEmbedJwplayer } from '~/composables/use-embed-jwplayer';
import type { CtaType } from '../../types';
import { computed } from 'vue';
import { useVideoPlaylistUrl } from '~/composables/use-video-playlist-url';

type CardBigVideoProps = {
  index: number | string;
  title: string,
  background?: string,
  videoPlaylistId?: string,
  badge?: string,
  cta: CtaType,
  gated?: boolean;
}

const props = withDefaults(defineProps<CardBigVideoProps>(), {
  background: '',
  videoPlaylistId: '',
  badge: '',
  gated: false
});

// computed property to determine if the background is an image or a video
const backgroundImage = computed(() => {
  if (props.background && props.background.endsWith('.mp4')) {
    return false;
  }

  return true;
});

const { playlistUrl } = useVideoPlaylistUrl(props.videoPlaylistId);

function embedJwplayer() {
  if (playlistUrl.value !== '') {
    // @ts-expect-error (jwplayer does not provide types)
    window.jwplayer(`video-player-${props.index}`).setup({
      'controls': false,
      'repeat': true,
      'nextUpDisplay': false,
      'displaydescription': false,
      'stretching': 'fill',
      'mute': true,
      'displaytitle': false,
      'playlist': playlistUrl.value
    });
  }
}

useEmbedJwplayer(embedJwplayer);

const { $event } = useNuxtApp();

function handleClick() {
  $event('card-big-video-click', { props : { title: props.title, href: props.cta.href } });
}
</script>

<style>
.jwplayer.jw-flag-aspect-mode.jw-stretch-fill {
  height: 100% !important;
}
</style>

